/* eslint-disable @typescript-eslint/no-explicit-any */
import { SessionUser } from "./../../models/user/user";
import { SessionStateService } from "./../user/session-state.service";
import { Injectable } from "@angular/core";
import { Observable, map, switchMap, forkJoin, of } from "rxjs";
import { Dropdown } from "../../models/navigation/dropdown";
import { UrlService } from "../url/url.service";
import { NavigationMenuItems } from "../../models/navigation/navigation-menu-items";
import { NavigationTile } from "../../models/navigation/navigation-tile";
import { Navigation } from "../../models/navigation/navigation";
import { REDIRECT_HOST_MAP } from "../../models/url/hostname";
import { UserPermissionId } from "../../models/permissions/feature-permissions";
import { BehaviorSubject } from 'rxjs';
import Utils from "../../utilities/utils";
import { ROUTE_PARAMETERS } from "../../utilities/constants";
import { UrlResources } from "../../models/url/url-resources";

@Injectable({ providedIn: "root" })
export class NavigationService {

  private sessionUser: SessionUser;
  private urlArray = new BehaviorSubject<[]>([]);
  private locationFound: boolean = false;

  constructor(private urlService: UrlService, private sessionState: SessionStateService) {
    this.sessionState.getSessionState().subscribe((sessionState) => {
      this.sessionUser = sessionState;
    });
  }

  public mapDropdownMenu(dropdown: any): Dropdown {
    const mappedDropdown: Dropdown = new Dropdown();
    this.setNameAndHref(dropdown, mappedDropdown);
    mappedDropdown.className = Utils.convertToClassName(dropdown.name);
    mappedDropdown.featurePermission = UserPermissionId.get(dropdown.featurePermission) || 0;
    mappedDropdown.navigationOrganizationRestriction = dropdown.navigationOrganizationRestriction;
    return mappedDropdown;
  }

  public mapDropdownItems(menuItems: any): NavigationMenuItems {
    const mappedMenu: NavigationMenuItems = new NavigationMenuItems();
    this.setNameAndHref(menuItems, mappedMenu);
    mappedMenu.name = menuItems.name;
    mappedMenu.className = Utils.convertToClassName(mappedMenu.name);
    mappedMenu.selected = menuItems.selected;
    mappedMenu.featurePermission = UserPermissionId.get(menuItems.featurePermission) || 0;
    menuItems.dropdownItems.forEach((element: any) => {
      const dropdownItem = this.mapDropdownMenu(element);
      mappedMenu.dropdown.push(dropdownItem);
    });
    return mappedMenu;
  }

  public mapNavigationMenu(navigationTile: any): NavigationTile {
    const mappedNavigationTile: NavigationTile = new NavigationTile();
    this.setNameAndHref(navigationTile, mappedNavigationTile);
    mappedNavigationTile.name = navigationTile.name;
    mappedNavigationTile.className = Utils.convertToClassName(mappedNavigationTile.name);
    mappedNavigationTile.imageSrc = navigationTile.imageSrc;
    mappedNavigationTile.selected = navigationTile.selected;
    mappedNavigationTile.backgroundColor = navigationTile.backgroundColor;
    mappedNavigationTile.featurePermission = UserPermissionId.get(navigationTile.featurePermission) || 0;
    navigationTile.menuItems?.forEach((element: any) => {
      const navigationMenuItem = this.mapDropdownItems(element);
      mappedNavigationTile.navigationMenuItems.push(navigationMenuItem);
    });
    return mappedNavigationTile;
  }

  public mapNavigationTile(navigationTileObj: any) {
    const mappedNavigation: Navigation = new Navigation();
    navigationTileObj?.forEach((element: any) => {
      const navigationTile = this.mapNavigationMenu(element);
      mappedNavigation.navigationTiles.push(navigationTile);
    });
    return this.setSelectedTile(mappedNavigation);
  }

  public setHiddenOrganizationHeaderURLs(urlArray: any): void {
    this.urlArray.next(urlArray);
  }

  public getHiddenOrganizationHeaderURLs(): Observable<[]> {
    return this.urlArray.asObservable();
  }

  public setSelectedTile(mappedNavigation: Navigation) {
    const tasks$: Observable<string>[] = [];
    this.locationFound = false;
    mappedNavigation.navigationTiles.forEach((navigationTile) => {
      navigationTile.selected = false;
      tasks$.push(this.setSelectedTileHref(navigationTile, navigationTile));
      navigationTile.navigationMenuItems.forEach((menuItem) => {
        menuItem.selected = false;
        tasks$.push(this.setSelectedTileHref(navigationTile, menuItem, menuItem));
        menuItem.dropdown.forEach((dropdownItem) => {
          tasks$.push(this.setSelectedTileHref(navigationTile, dropdownItem, menuItem));
        });
      });
    });
    return forkJoin(tasks$).pipe(map(() => mappedNavigation));
  }

  private setSelectedTileHref(
    navigationTile: NavigationTile,
    navigationTileChild: NavigationTile | NavigationMenuItems | Dropdown,
    navigationMenu?: NavigationMenuItems
  ): Observable<string> {
    let url = location.href;

    if(!url.includes("BCCalcMemberSelector")){
      url = this.getAbsolutePath(location.href);
    }

    return navigationTileChild.href.pipe(
      switchMap((href) => {
        const hrefAbsolutePath = this.getAbsolutePath(href);
        if ((url.toLowerCase() === hrefAbsolutePath.toLowerCase()) && !this.locationFound) {
          navigationTile.selected = true;
          if (navigationMenu) {
            navigationMenu.selected = true;
            this.locationFound = true;
          }
        }
        return navigationTileChild.href;
      })
    );
  }

  private setNameAndHref(jsonData: any, mappedObject: Dropdown | NavigationMenuItems | NavigationTile): void {
    const hostname = this.urlService.checkIfLocalhost(jsonData.brand);
    const queryParams = this.urlService.buildQueryParameters(jsonData.queryParameters, this.sessionUser);
    let projectId = "";
    let attachmentId = "";
    let dealerOrgId = "";
    let contactId = "";
    let builderId = "";
    let reportId = "";
    const locationHrefLowercase = location.href.toLowerCase();

    let specialCasePathInHref = "";
    if (locationHrefLowercase.includes(ROUTE_PARAMETERS.MANAGE_PROJECT)) {
      specialCasePathInHref = ROUTE_PARAMETERS.MANAGE_PROJECT;
    }
    else if (locationHrefLowercase.includes(ROUTE_PARAMETERS.MANAGE_CREATE_CONTACT)) {
      specialCasePathInHref = ROUTE_PARAMETERS.MANAGE_CREATE_CONTACT;
    }

    if (specialCasePathInHref) {
      //handle special case path variables
      const builderIdStartIndex = locationHrefLowercase.indexOf(specialCasePathInHref) + specialCasePathInHref.length;
      builderId = this.extractIdFromPath(builderIdStartIndex);
      //projectId start index is the builderId start index + length of the builderId + 1 to account for the '/' after builderId
      const projectIdStartIndex = locationHrefLowercase.indexOf(specialCasePathInHref) + specialCasePathInHref.length + builderId.length + 1;
      projectId = this.extractIdFromPath(projectIdStartIndex);
    }
    else {
      //handle normal path variables
      if (locationHrefLowercase.includes(ROUTE_PARAMETERS.PROJECT)) {
        projectId = this.extractIdFromPath(locationHrefLowercase.indexOf(ROUTE_PARAMETERS.PROJECT) + ROUTE_PARAMETERS.PROJECT.length);
      }
      if (locationHrefLowercase.includes(ROUTE_PARAMETERS.ATTACHMENT)) {
        attachmentId = this.extractIdFromPath(locationHrefLowercase.indexOf(ROUTE_PARAMETERS.ATTACHMENT) + ROUTE_PARAMETERS.ATTACHMENT.length);
      }
      if (locationHrefLowercase.includes(ROUTE_PARAMETERS.QUOTESHEET)) {
        attachmentId = this.extractIdFromPath(locationHrefLowercase.indexOf(ROUTE_PARAMETERS.QUOTESHEET) + ROUTE_PARAMETERS.QUOTESHEET.length);
      }
      if (locationHrefLowercase.includes(ROUTE_PARAMETERS.CUSTOM_DEALER)) {
        dealerOrgId = this.extractIdFromPath(locationHrefLowercase.indexOf(ROUTE_PARAMETERS.CUSTOM_DEALER) + ROUTE_PARAMETERS.CUSTOM_DEALER.length);
      }
      if (locationHrefLowercase.includes(ROUTE_PARAMETERS.CONTACT)) {
        contactId = this.extractIdFromPath(locationHrefLowercase.indexOf(ROUTE_PARAMETERS.CONTACT) + ROUTE_PARAMETERS.CONTACT.length);
      }
      if (locationHrefLowercase.includes(ROUTE_PARAMETERS.BUILDER)) {
        builderId = this.extractIdFromPath(locationHrefLowercase.indexOf(ROUTE_PARAMETERS.BUILDER) + ROUTE_PARAMETERS.BUILDER.length);
      }
      if (locationHrefLowercase.includes(ROUTE_PARAMETERS.OPTI_REPORTS)) {
        reportId = this.extractIdFromPath(locationHrefLowercase.indexOf(ROUTE_PARAMETERS.OPTI_REPORTS) + ROUTE_PARAMETERS.OPTI_REPORTS.length);
      }
    }

    mappedObject.name = jsonData.name;
    mappedObject.featureFlag = jsonData.hasOwnProperty("featureFlag") ? jsonData.featureFlag : true;
    mappedObject.href = of(
      this.urlService.buildUrlResource(
        (hostname !== "" ? hostname : REDIRECT_HOST_MAP.get(jsonData.brand)) + "/" + jsonData.href + queryParams,
        this.sessionUser,
        new UrlResources(projectId,
          attachmentId,
          builderId,
          dealerOrgId,
          contactId,
          reportId)
      )
    );
  }

  private getAbsolutePath(url: string): string {
    //return url without query parameters
    if(url.includes("BCCalcMemberSelector")){
      return url;
    }
    return url.split("?")[0];
  }

  private extractIdFromPath(startIndex: number): string {
    // Find the end index of the ID by searching for the next '/' or '?'
    var slashIndex = location.href.indexOf('/', startIndex);
    var questionIndex = location.href.indexOf('?', startIndex);
    var endIndex: number;
    if (slashIndex >= 0 && questionIndex >= 0) {
      //both exist in the url, so determine which one is closer 
      endIndex = Math.min(slashIndex, questionIndex);
    } else {
      //this sets endIndex to whichever one was found, or -1 if neither were found
      endIndex = slashIndex >= 0 ? slashIndex : questionIndex;
    }

    // Extract the ID using substring
    let id: string;
    if (endIndex !== -1) {
      id = location.href.substring(startIndex, endIndex);
    } else {
      // If there is no '/' or '?' after the ID, no end index
      id = location.href.substring(startIndex);
    }
    return id;
  }
}
